import { Box, Container, Button, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import GImage from '../Utility/GImage';
import FadeInAnimation from '../UX/FadeInAnimation';
import RouterLink from '../Utility/Routing/RouterLink';
import React from 'react';

export const SliderItem = ({ btnVariant, params, children }) => {
  return (
    <Container
      variant={'layoutContainer2'}
      as={motion.div}
      whileTap={{ cursor: 'grabbing' }}
      animate={{ scale: 0.8, opacity: 0.0, y: 22, x: 0 }}
      whileInView={{
        scale: 1,
        opacity: 1,
        y: 0,
        x: 0,
        transition: { type: 'spring', duration: 1.5 },
      }}
      minH={{ base: '50vh', md: '30vh' }}
      boxSizing='border-box'
      px={{ base: 2, md: 16 }}
      py={{ base: 6, md: 6 }}
    >
      <Box
        bg={'#222'}
        h='100%'
        minH={{ base: '50vh', md: '30vh' }}
        rounded={'xl'}
        overflow={'hidden'}
        as={motion.div}
      >
        {children}
      </Box>
    </Container>
  );
};
