import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Grid,
  GridItem,
  Text,
  shouldForwardProp,
  Heading,
} from '@chakra-ui/react';
import {
  motion,
  useMotionValue,
  useScroll,
  AnimatePresence,
  useDragControls,
  isValidMotionProp,
} from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SliderItem } from './SliderItem';

import FadeInAnimation from '../UX/FadeInAnimation';
import RouterLink from '../Utility/Routing/RouterLink';
import GImage from '../Utility/GImage';

function ArbeitnehmerueberlassungsvermittlungSlider({ backG }) {
  const x = useMotionValue(0);
  useEffect(() => {
    x.onChange(() => {
      console.log('Damn it');
      console.log(x.get());
    });
  }, []);

  return (
    <Box bg={backG}>
      <Container pt={24} px={0} variant={''}>
        <>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Grid display={'grid'} templateRows='1fr' templateColumns='1fr'>
              <GridItem colSpan={1} rowSpan={1} colStart={1} rowStart={1}>
                <GImage
                  minH={{ base: '40vh', md: '50vh' }}
                  opacity={0.4}
                  src={'mood/mediensturmer-aWf7mjwwJJo-unsplash.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'100%'}
                  objectFit={'cover'}
                  objectPosition={'center'}
                />
              </GridItem>
              <GridItem
                zIndex={999999999999}
                p={{ base: '8', md: '12', lg: '24' }}
                colSpan={1}
                rowSpan={1}
                colStart={1}
                rowStart={1}
              >
                <FadeInAnimation
                  delay={0.5}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                  mb={8}
                >
                  <Heading
                    as='h2'
                    size={{ base: 'md', md: 'lg', lg: 'xl' }}
                    color={'brand.blue'}
                  >
                    Arbeitnehmer&shy;überlassung und -vermittlung
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  delay={1}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                >
                  <Heading
                    size={{ base: 'sm', md: 'sm', lg: 'md' }}
                    w={{ base: '100%', md: '76%' }}
                    lineHeight={'base'}
                    color={'brand.gray.900'}
                  >
                    Die kdw HR und wir als Menschen dahinter schaffen echte
                    Partnerschaften mit Unternehmen. Unser Ziel ist Vertrauen.
                  </Heading>
                </FadeInAnimation>
              </GridItem>
            </Grid>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box
              p={{ base: '8', md: '12', lg: '24' }}
              w={{ base: '100%', md: '96%' }}
            >
              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={12}
              >
                <Heading
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  color={'white'}
                  lineHeight={'base'}
                >
                  Auf allen Ebenen bieten wir unseren Kunden sowie Mitarbeitern
                  durch Engagement und Nachhaltigkeit eine besondere Qualität
                  und ein besonderes Umfeld, welche wir durch ein konsequentes
                  Reflektieren unserer Arbeitsschritte und Prozesse erreichen
                  und kontinuierlich optimieren.
                  <br />
                  <br /> Werte wie Ehrlichkeit, Offenheit, Wertschätzung und
                  Zuverlässigkeit begleiten uns in allem, was wir tun.
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Grid display={'grid'} templateRows='1fr' templateColumns='1fr'>
              <GridItem colSpan={1} rowSpan={1} colStart={1} rowStart={1}>
                <GImage
                  minH={{ base: '40vh', md: '50vh' }}
                  opacity={0.4}
                  src={'mood/mediensturmer-aWf7mjwwJJo-unsplash.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'100%'}
                  objectFit={'cover'}
                  objectPosition={'center'}
                />
              </GridItem>
              <GridItem
                zIndex={999999999999}
                p={{ base: '8', md: '12', lg: '24' }}
                colSpan={1}
                rowSpan={1}
                colStart={1}
                rowStart={1}
              >
                <FadeInAnimation
                  delay={0.5}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                  mb={8}
                >
                  <Heading
                    as='h2'
                    size={{ base: 'md', md: 'lg', lg: 'xl' }}
                    color={'brand.blue'}
                  >
                    Unser Fokus liegt in der qualifizierten Personalgewinnung.
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation
                  delay={1}
                  duration={0.9}
                  threshold={0}
                  initialX={0}
                  initialY={12}
                >
                  <Heading
                    size={{ base: 'sm', md: 'sm', lg: 'md' }}
                    w={{ base: '100%', md: '76%' }}
                    lineHeight={'base'}
                    color={'brand.gray.900'}
                  >
                    Wir finden für Sie die passenden Mitarbeiter bis hin ins
                    mittlere und hohe Management.
                  </Heading>
                </FadeInAnimation>
              </GridItem>
            </Grid>
          </SliderItem>
          <SliderItem as={motion.div} btnVariant={'solid'}>
            <Box p={{ base: '8', md: '12', lg: '24' }} w={'100%'}>
              <FadeInAnimation
                delay={0.5}
                duration={0.9}
                threshold={0}
                initialX={0}
                initialY={12}
                mb={8}
              >
                <Heading
                  as='h2'
                  size={{ base: 'md', md: 'lg', lg: 'xl' }}
                  color={'brand.blue'}
                >
                  Mehr als die Summe seiner Teile.
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={12}
              >
                <Heading
                  as='h3'
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  color={'white'}
                  lineHeight={'base'}
                >
                  In Kooperation mit klassischen Headhuntern, HR-Spezialisten
                  und Marketingprofis, durch individuelle Recruiting-Kampagnen
                  sowie nicht zuletzt durch unser breites Netzwerk stellen wir
                  Ihrem Unternehmen die optimalen Mitarbeiter, Führungskräfte
                  oder Interim-Manager zur Verfügung.
                </Heading>
              </FadeInAnimation>

              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={12}
              >
                <Heading
                  as='h3'
                  size={{ base: 'sm', md: 'sm', lg: 'md' }}
                  w={{ base: '100%', md: '76%' }}
                  lineHeight={'base'}
                  color={'white'}
                  mt={4}
                >
                  In Form eines Arbeitnehmerüberlassungs-Vertrags,
                  projektbezogen oder per Direktvermittlung sind wir individuell
                  flexibel.
                </Heading>
              </FadeInAnimation>
            </Box>
          </SliderItem>
          <SliderItem
            display='flex'
            w={'100%'}
            px={'10%'}
            as={motion.div}
            btnVariant={'solid'}
          >
            <Flex
              flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
              w={'100%'}
              h={'100%'}
            >
              <FadeInAnimation
                delay={0.5}
                duration={0.5}
                threshold={0}
                initialX={0}
                initialY={0}
                w={'100%'}
                h={'100%'}
              >
                <GImage
                  minH={{ base: '30vh', md: '50vh' }}
                  src={'aaron/kdw-6649.jpg'}
                  alt={' '}
                  w={'100%'}
                  h={'100%'}
                  objectFit={'cover'}
                  objectPosition={'left'}
                />
              </FadeInAnimation>
              <Flex
                p={{ base: 12, md: 8, lg: 8 }}
                px={12}
                w={'100%'}
                h={'100%'}
                minW={'40%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'start'}
              >
                <Heading
                  mt={4}
                  mb={4}
                  as='h3'
                  size={{ base: 'md', md: 'lg', lg: 'xl' }}
                  color={'white'}
                >
                  Wir stehen jederzeit für Sie bereit!
                </Heading>
                <RouterLink
                  mx={'auto'}
                  display={'inline-block'}
                  isExternal={'false'}
                  link={'/kontakt'}
                >
                  <Button
                    mt={2}
                    px={14}
                    pt={4}
                    pb={9}
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.blue'
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </Flex>
            </Flex>
          </SliderItem>
        </>
      </Container>
    </Box>
  );
}

export default ArbeitnehmerueberlassungsvermittlungSlider;
